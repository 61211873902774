<script setup lang="ts">
import type { RouteLocationNamedRaw } from 'vue-router'

const route = useRoute()
const currentRouteName = computed(() => route.name?.toString())
const isActiveMenu = (name: string) => currentRouteName.value === name

const color = COLOR.gray60
const menuColor = COLOR.green50
const menuBackgroundColor = COLOR.green5
const hoveredMenuBackgroundColor = COLOR.gray5

defineProps<{
  title: string
  menus: { to: RouteLocationNamedRaw; text: string }[]
}>()
</script>

<template>
  <p class="sidebar-menu-title">{{ title }}</p>
  <ul class="sidebar-menu-list">
    <li
      v-for="(menu, i) in menus"
      :key="i"
      class="sidebar-menu-item sub"
      :class="{ active: isActiveMenu(menu.to.name?.toString() ?? '') }"
    >
      <SidebarLink :to="menu.to" :text="menu.text" />
    </li>
  </ul>
</template>

<style scoped lang="scss">
.sidebar-menu-title {
  font-size: 1rem;
  padding: 16px 0 8px 24px;
}
.sidebar-menu-list {
  padding: 8px 0px;
  .sidebar-menu-item {
    border-left: 4px solid transparent;
    a {
      display: block;
      color: v-bind(color);
      padding: 8px;
    }
    &.sub {
      a {
        padding-left: 40px;
      }
    }
    &:not(.active):hover {
      background-color: v-bind(hoveredMenuBackgroundColor);
    }
  }
  .active {
    background-color: v-bind(menuBackgroundColor);
    border-left: 4px solid v-bind(menuColor);
    a {
      font-weight: bold;
      color: v-bind(menuColor);
    }
  }
}
</style>
