<script setup lang="ts">
import type { RouteLocationNamedRaw } from 'vue-router'

const route = useRoute()

type Props = {
  to: RouteLocationNamedRaw
  text: string
  icon?: string
  inactive?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  icon: '',
  inactive: false,
})

const isActive = computed(() => {
  if (props.inactive) return false
  if (!route.name || !props.to.name) return false
  return route.name.toString().startsWith(props.to.name.toString())
})

const navigate = async () => {
  await navigateTo(props.to)
}
</script>

<template>
  <a :class="{ 'is-active': isActive }" @click="navigate">
    <span class="icon-text">
      <span v-if="icon" class="icon">
        <i class="fas" :class="icon"></i>
      </span>
      {{ text }}
    </span>
  </a>
</template>
